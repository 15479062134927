export const GetBrands = async () => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetBrands" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); 
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};
 
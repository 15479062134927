import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../useWindowSize";
import { GetShops } from "../../data/ShopsController";
import { GetCategoriesByTopMenu } from "../../data/categoriesData";

export default function Bottompart() {
  const [shops, setShops] = useState([]);
  const [categoriesByTopMenu, setCategoriesByTopMenu] = useState([]);

  useEffect(() => {
    GetCategoriesByTopMenu(setCategoriesByTopMenu);
  }, [categoriesByTopMenu]);

  useEffect(() => {
    GetShops().then((Shops) => setShops(Shops));
  }, []);

  // useEffect(() => {
  //   GetShops().then((Shops)=>setShops(Shops))
  // }, []);

  const navigate = useNavigate();

  const handleScrollToOffers = () => {
    const offersElement = document.getElementById("offers");
    if (offersElement) {
      offersElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleScrollToFeature = () => {
    const offersElement = document.getElementById("middlebanner");
    if (offersElement) {
      offersElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const setCurrency = (currency) => {
    localStorage.setItem("currency", JSON.stringify(currency));
  };
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const checkCart = () => {
      const cartStored = localStorage.getItem("cart");
      if (cartStored) {
        setCart(JSON.parse(cartStored));
      }
    };
    checkCart();
    const intervalId = setInterval(checkCart, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const bottomPart = document.querySelector(".bottom-part");
  //     const topHeader = document.querySelector(".top-header");
  //     const stickys = [topHeader, bottomPart];

  //     if (window.scrollY >= window.innerHeight * 0.2) {
  //       topHeader.style.position = "fixed";
  //       topHeader.style.top = "0";
  //       topHeader.style.width = "100%";
  //       topHeader.style.zIndex = "900";
  //       topHeader.style.padding = "5px";

  //       bottomPart.style.position = "fixed";
  //       bottomPart.style.top = `${topHeader.offsetHeight}px`;
  //       bottomPart.style.width = "100%";
  //       bottomPart.style.zIndex = "899";
  //       bottomPart.style.background = "#fff";
  //       bottomPart.style.padding = "5px";
  //     } else {
  //       stickys.forEach((element) => {
  //         element.style.position = "";
  //         element.style.top = "";
  //         element.style.width = "";
  //         element.style.zIndex = "";
  //         element.style.background = "";
  //         element.style.padding = "";
  //       });
  //     }
  //   };
  //   setMenuOpen(false);
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const bottomPart = document.querySelector(".bottom-part");
      const topHeader = document.querySelector(".top-header");
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        [topHeader, bottomPart].forEach((element) => {
          element.style.position = "relative";
          element.style.top = "";
          element.style.width = "";
          element.style.zIndex = "900";
          element.style.background = "";
          element.style.padding = "";
        });
      } else if (currentScrollY < lastScrollY) {
        // User is scrolling up
        topHeader.style.position = "fixed";
        topHeader.style.top = "0";
        topHeader.style.width = "100%";
        topHeader.style.zIndex = "900";
        topHeader.style.padding = "0";
        topHeader.style.opacity = "0.9";

        bottomPart.style.position = "fixed";
        bottomPart.style.top = `${topHeader.offsetHeight}px`;
        bottomPart.style.width = "100%";
        bottomPart.style.zIndex = "899";
        bottomPart.style.background = "#fff";
        bottomPart.style.padding = "0";
        bottomPart.style.opacity = "0.9";
      } else {
        // User is scrolling down
        [topHeader, bottomPart].forEach((element) => {
          element.style.position = "";
          element.style.top = "";
          element.style.width = "";
          element.style.zIndex = "";
          element.style.background = "";
          element.style.padding = "";
        });
      }

      lastScrollY = currentScrollY;
    };

    setMenuOpen(false);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const { isMobile } = useWindowSize();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
    setMenuOpen(false);
  };

  const handleScrollToHome = () => {
    window.scrollTo(0, 0);
  };

  const openCart = () => {
    sessionStorage.setItem("modalIsOpen", "true");
  };

  const groupedByGDescription = categoriesByTopMenu.reduce((acc, item) => {
    if (!acc[item.GDesription]) {
      acc[item.GDesription] = [];
    }
    acc[item.GDesription].push(item.Category);
    return acc;
  }, {});

  return (
    <div
      className="bottom-part bottom-light"
      // style={{opacity:'0.9', zIndex:'999'}}
    >
      {/* Mobile bars */}
      {/* <div
        className="col-lg-2 text-end"
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 99,
          padding: "15px",
          display: isMobile ? "block" : "none",
        }}
       
      >
        <ul>
          <li className="mx-2 mobile-cart" style={{ fontSize: "24px" }}  onClick={toggleMenu}>
            <a href="#">
              <i className="fa fa-bars"></i>
            </a>
          </li> */}
      {/* <li className="mobile-cart mx-2" style={{ fontSize: "24px"  }} 
                          onClick={() => openCart()}
                        >
                          <div>
                            <img
                              src="./src/assets/images/icon/cart-1.png"
                              // className="img-fluid blur-up lazyload"
                              alt=""
                            /> 
                           
                          </div>
                          <span className="cart_qty_cls">
                            {cart ? cart.length : "0"}
                          </span>
                        </li> */}
      {/* </ul>
      </div> */}
      {/*End Mobile bars */}

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="main-menu">
              <div
                className="menu-left"
                style={
                  isMobile
                    ? {
                        display: menuOpen ? "block" : "none",
                        width: "100%",
                        height: "100%",
                        padding: "4%",
                      }
                    : {}
                }
              >
                <div className="main-nav-center">
                  <nav id="main-nav" className="text-start">
                    {/* <div className="toggle-nav" onClick={toggleMenu}>
                <i className="fa fa-bars sidebar-bar"></i>
              </div> */}
                    <ul
                      id="main-menu"
                      className={`sm pixelstrap   ${
                        isMobile ? "sm-vertical" : "sm-horizontal"
                      } `}
                    >
                      <li>
                        <div
                          className="mobile-back text-end"
                          onClick={toggleMenu}
                        >
                          Back
                          <i
                            className="fa fa-angle-right ps-2"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleNavigate("/")}
                          onTouchStart={handleScrollToHome}
                          onMouseEnter={handleScrollToHome}
                        >
                          Home
                        </a>
                      </li>
                      <li className="mega" id="hover-cls">
                        <a style={{ cursor: "pointer" }}>
                          feature
                          {/* <div className="lable-nav">new</div> */}
                        </a>
                        <ul className="mega-menu full-mega-menu">
                          <li>
                            <div className="container">
                              <div className="row">
                                <div className="col mega-box">
                                  <div className="link-section">
                                    <div className="menu-content">
                                      <div className="row">
                                        {Object.keys(groupedByGDescription).map(
                                          (GDesription, i) => (
                                            <div
                                              key={i}
                                              className="col"
                                            >
                                              <div className=" text-danger">{GDesription}  <i
                                                      className="ms-2 fa fa-bolt icon-trend"
                                                      aria-hidden="true"
                                                    ></i></div>
                                              <ul>
                                                {groupedByGDescription[
                                                  GDesription
                                                ].map((category, j) => (
                                                  <li key={j}> 
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {category}
                                                    </a>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          )
                                        )}
                                        <div
                                          onClick={() =>
                                            handleNavigate("/Categories")
                                          }
                                        >
                                          <a style={{ cursor: "pointer" }}>
                                            Categories
                                            <i
                                              className="ms-2 fa fa-bolt icon-trend"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="row my-4">
                            <div className="col-12">
                              <img
                                src="./src/assets/images/menu-banner.jpg"
                                // className="img-fluid mega-img"
                                alt=""
                              />
                            </div>
                          </div> */}
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        {/* <a href="#shop">shop</a> */}
                        <a
                          href="#shop"
                          className="has-submenu"
                          id="sm-17223528942335837-3"
                          aria-haspopup="true"
                          aria-controls="sm-17223528942335837-4"
                          aria-expanded="false"
                        >
                          shops<span className="sub-arrow"></span>
                        </a>
                        <ul>
                          {shops &&
                            shops.map((shop, i) => (
                              <li key={i}>
                                <a>
                                  <span
                                    className="new-tag"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {shop.ShopName}
                                  </span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                      <li className="mega" id="hover-cls">
                        <a style={{ cursor: "pointer" }}>Products</a>
                        <ul className="mega-menu full-mega-menu">
                          <li>
                            <div className="container">
                              <div className="row">
                                <div className="col mega-box">
                                  <div className="link-section">
                                    <div className="menu-content">
                                      <ul>
                                        <li>
                                          <a>
                                            Remomended For You
                                            <i
                                              className="ms-2 fa fa-bolt icon-trend"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </li>
                                        <li
                                          onClick={() =>
                                            handleNavigate("/Categories")
                                          }
                                        >
                                          <a style={{ cursor: "pointer" }}>
                                            Categories
                                            <i
                                              className="ms-2 fa fa-bolt icon-trend"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          // onTouchStart={handleScrollToOffers}
                          // onMouseEnter={handleScrollToOffers}
                        >
                          Offers
                          <div className="lable-nav">new</div>
                        </a>
                        <ul>
                          <li>
                            <a href="#">vendor</a>
                            <ul>
                              <li>
                                <a href="vendor-dashboard.html">
                                  vendor dashboard
                                </a>
                              </li>
                              <li>
                                <a href="vendor-profile.html">vendor profile</a>
                              </li>
                              <li>
                                <a href="become-vendor.html">become vendor</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">blog</a>
                        <ul>
                          <li>
                            <a href="blog-page.html">left sidebar</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* </div> */}
              </div>

              {/* //End Menu LeftS Side  */}

              {/* //Right Side  */}
              <div className="menu-right pull-right">
                <div>
                  <div className="icon-nav">
                    <ul>
                      <li className="onhover-div mobile-search d-xl-none d-inline-block">
                        <div>
                          <img
                            src="src/assets/images/icon/bar.png"
                            onClick={() => toggleMenu()}
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i
                            className="fa fa-bars"
                            onClick={() => toggleMenu()}
                          ></i>
                        </div>
                      </li>
                      <li className="onhover-div mobile-setting">
                        <div>
                          <img
                            src="src/assets/images/icon/setting.png"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i className="ti-settings"></i>
                        </div>
                        <div className="show-div setting">
                          <h6>language</h6>
                          <ul>
                            <li>
                              <a href="#">english</a>
                            </li>
                            {/* <li><a href="#">french</a></li> */}
                          </ul>
                          <h6>currency</h6>
                          <ul className="list-inline">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                onClick={() =>
                                  setCurrency({ unit: "L.L", rate: "89000" })
                                }
                              >
                                LBP
                              </a>
                            </li>
                            <li style={{ cursor: "pointer" }}>
                              <a
                                onClick={() =>
                                  setCurrency({ unit: "€", rate: "0.92" })
                                }
                              >
                                Euro
                              </a>
                            </li>
                            <li style={{ cursor: "pointer" }}>
                              <a
                                onClick={() =>
                                  setCurrency({ unit: "$", rate: "1" })
                                }
                              >
                                Dollar
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        className="onhover-div mobile-cart"
                        onClick={() => openCart()}
                      >
                        <div>
                          <img
                            src="src/assets/images/icon/cart-1.png"
                            className="img-fluid blur-up lazyload"
                            alt=""
                          />{" "}
                          <i className="ti-shopping-cart"></i>{" "}
                        </div>
                        <span className="cart_qty_cls">
                          {cart ? cart.length : "0"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* //End Right Side  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

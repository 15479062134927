import Topheader from "./components/header/top-header";
import Bottompart from "./components/header/bottom-part";
import { FooterSection } from "./components/footer";
import ArzRouter from "./router";
import { BrowserRouter as Router } from "react-router-dom";
import { ModatCart } from "./components/modalCart";

export default function App() {
  return (
    <Router>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <header className="header-style-5 header-style-24 border-style m-0">
          <div className="mobile-fix-option"></div>
          <Topheader />
          {/* <Headercontainer /> */}
          <Bottompart />
        </header>

        <main 
        // style={{ marginTop:'100px' }}
        >

      
          <ArzRouter />
        </main>
        <FooterSection style={{ marginTop: "auto" }} />

        <ModatCart />
      </div>
    </Router>
  );
}

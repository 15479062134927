import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css.css";
import useWindowSize from "./useWindowSize";
import { adminUrl } from "../../public/src/urls";
// import { ShopNow } from "./ShopNow";
import { GetShops } from "../data/ShopsController";

// BlogSection component
export const ShopsSlider = () => {
  const { isMobile } = useWindowSize();
  const [Shops, setShops] = useState([]);
 
  useEffect(() => {
    const fetchedProductInfos = async () => { 
      try {
        const Shops = await GetShops();
        setShops(Shops); 
      } catch (err) {
        console.error("Failed to fetch products");
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
  }, []);
  
  // Slider settings
  const settings = {
    dots: isMobile ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 6,
    slidesToScroll: isMobile ? 1 : 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
  };
  //src date text
  return (
    <section className="blog ratio2_3 gym-blog section-b-space overflow-hidden" 
    style={{backgroundColor: isMobile ? '' : '#', opacity:'0.9'}}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Slider
              {...settings}
              className="slide-2 no-arrow  "
            >
              {Shops.map((item, index) =>{
               const fullUrl = `${adminUrl}/backend/${item.Logo}`;
               // Encode the full URL
               const encodedUrl = encodeURI(fullUrl);
                return (
                
                <div
                  key={index}
                  className="blog-slide"
                  // onClick={() => ShopNow(item)}
                  // style={{height: isMobile ? '10vh' : '25vh'}}
                >
                  {/* <a href="#" tabIndex="0"> */}
                  <div
                    className="classic-effect"
                    style={{ 
                      borderRadius: "5%",
                    }}
                  >
                    <div
                      className="bg-size blur-up lazyloaded"
                      style={{  
                        // backgroundImage: `url(${adminUrl}/backend/${encodedLogo})`,
                        backgroundImage: `url(${encodedUrl})`,
                        backgroundSize: "contain",
                        backgroundRepeat:'no-repeat',
                        backgroundPosition: "center center",
                        display: "block",
                        borderRadius: "5%",
                        margin:'1%',
                        // backgroundColor: '#F16850',
                        // opacity:'0.7', 
                        // height:'10%',
                        // width:'10%'
                      }}
                    >
                      <img
                        alt=""
                        src={adminUrl + '/backend/' + item.Logo}
                        className="img-fluid blur-up lazyload bg-img"
                        style={{ display: "none" }}
                        readOnly
                      />
                      <span></span>
                      {/* <h6>by: {item.ShopName}</h6> */}
                    </div>
                  </div> 
                  {/* </a> */}
                  {/* <div className="blog-details">
                    <h4>{item.HasDiscount ? item.DiscountPrice : item.Price > 0 ? item.Price : ''}</h4>
                    <a href="#" tabIndex="0">
                      <p>{item.ProductName}</p>
                    </a>
                    <h6>{item.ShopName}</h6>
                  </div> */}
                </div>
              )}
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

import { useLocation } from "react-router-dom";
import { decrypt } from "../components/encdesc";
import { GetProductsByID } from "../data/productsData";
import { ProductSection } from "./ProductSection";
import { ProductDetails } from "./ProductDetails";

import { useEffect, useState } from "react";
import { Related } from "./related";
import { GetShops } from "../data/ShopsController"; 

export default function ProductInfo() {
  const location = useLocation();
  const encryptedData =
    location.state?.encryptedData || localStorage.getItem("encryptedData");

  useEffect(() => {
    if (encryptedData) {
      localStorage.setItem("encryptedData", encryptedData);
    }
  }, [encryptedData]);

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [Shops, setShops] = useState(null);
  const [Shop, setShop] = useState();

  // useEffect(() => {
  //   const fetchShop = async () => {
  //     try {
  //       await GetShops(setShops); 
  //       const selectedShop = Shops.find((shop) => shop.ShopID === product.ShopID); 
  //       setShop(selectedShop);
  //     } catch (err) {
  //       console.log("Failed to fetch products");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchShop();
  // }, [Shops, Shop, product]);

  useEffect(() => {
    const fetchedProductInfos = async () => { 
      try {
        const Shops = await GetShops().find((shop) => shop.ShopID === product.ShopID);
        setShops(Shops); 
      } catch (err) {
        console.error("Failed to fetch products");
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
  }, [product]);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = decrypt(encryptedData);
        // const data = {ProductID: 51}
        const fetchedProduct = await GetProductsByID(data.ProductID);
        setProduct(fetchedProduct);
      } catch (err) {
        console.log("Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [encryptedData]);

  const bottomPart = document.querySelector(".bottom-part");
  return (
    <>
  
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <ProductSection Product={product} Shop={Shop} />
      <ProductDetails Product={product} />
      <Related Product={product} /> 
    </>
  );
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { GetrelatedProducts } from "../data/productsData";
import { adminUrl } from "../../public/src/urls";
import useShopNow  from "../components/ShopNow";
import { CurrencyConverter } from "../components/Currency";
export const Related = ({ Product }) => {
    const shopNow = useShopNow();

  const [Relateds, settRelated] = useState([]); 
  useEffect(() => {
    const fetchedProductInfos = async () => { 
      try {
        const RelatedProducts = await GetrelatedProducts(Product.CategoryID);
        settRelated(RelatedProducts);
        // console.log(ProductInfos);
      } catch (err) {
        console.error("Failed to fetch products");
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
  }, [Product]);
 

  return (
    <section className="section-b-space ratio_asos">
      <div className="container">
        <div className="row">
          <div className="col-12 product-related">
            <h2>related products</h2>
          </div>
        </div>
        <div className="row search-product">

{Relateds && Relateds.map((Related,i)=> (   

          <div key={i} className="col-xl-2 col-md-4 col-6 m-0 rounded p-2"
          onClick={() => shopNow(Related)}
          >
            <div className="product-box">
              <div className="img-wrapper">
                <div className="front">
                  <a href="#">
                    <img
                      src={adminUrl+'/backend/'+Related.Photo}
                      className="img-fluid blur-up lazyload bg-img rounded"
                      alt=""
                    />
                  </a>
                </div>
                <div className="back">
                  <a href="#">
                    <img
                       src={adminUrl+'/backend/'+Related.Photo}
                      className="img-fluid blur-up lazyload bg-img"
                      alt=""
                    />
                  </a>
                </div>
                <div className="cart-info cart-wrap">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#addtocart"
                    title="Add to cart"
                  >
                    <i className="ti-shopping-cart"></i>
                  </button>{" "}
                  <a href="" title="Add to Wishlist">
                    <i className="ti-heart" aria-hidden="true"></i>
                  </a>{" "}
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#quick-view"
                    title="Quick View"
                  >
                    <i className="ti-search" aria-hidden="true"></i>
                  </a>{" "}
                  <a href="compare.html" title="Compare">
                    <i className="ti-reload" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div className="product-detail">
                <div className="rating">
                  <i className="fa fa-star"></i> <i className="fa fa-star"></i>{" "}
                  <i className="fa fa-star"></i> <i className="fa fa-star"></i>{" "}
                  <i className="fa fa-star"></i>
                </div>
                <a href="product-page(no-sidebar).html">
                  <h6>{Related.ProductName}</h6>
                </a>
                <h4>{Related.HasDiscount ? CurrencyConverter(Related.DiscountPrice) : CurrencyConverter(Related.Price)}</h4>
                <del>{Related.HasDiscount ? CurrencyConverter(Related.Price) : ''}</del>
                {/* <ul className="color-variant">
                  <li className="bg-light0"></li>
                  <li className="bg-light1"></li>
                  <li className="bg-light2"></li>
                </ul> */}
              </div>
            </div>
          </div>

))}


 
        </div>
      </div>
    </section>
  );
};

Related.propTypes = {
  Product: PropTypes.object.isRequired,
};
Related.defaultProps = {
  Product: {},
};

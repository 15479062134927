import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";

export default function Topheader() {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  // const [cart, setCart] = useState([]);

  // useEffect(() => {
  //   const cartStored = localStorage.getItem("cart");
  //   setCart(JSON.parse(cartStored));
  // }, [setCart]);
 
  return (
    <div
      className="top-header top-header-dark"
      // style={{opacity:'0.9' }}

    >
      <div className="container">
        <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
            <div className="col-lg-3">
              <img src="./src/assets/images/favicon/28.png"
              className="header-contact"
              style={{height:'75px' , border: `0px solid #f00`,}}
            />
            </div> 
          <div className="col-lg-7">

            <div className="header-contact">
              <ul>
                <li  style={{fontSize:'16px', fontWeight:'999'}}>Discover Variety, Shop Smart at Al Arz Mall</li>
                <li style={{fontSize:'10px', fontWeight:'700'}}>
                  <i className="fa fa-phone" aria-hidden="true"></i>Call Us:
                  +961 1 000 000
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 text-end">
            <ul className="header-dropdown">
              <li className="mobile-wishlist">
                <a href="#">
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </a>
              </li>
              <li
                className="onhover-dropdown mobile-account"
                style={{ zIndex: 9999 }}
              >
                {" "}
                <i className="fa fa-user" aria-hidden="true"></i>
                My Account
                <ul className="onhover-show-div">
                  {isAuthenticated ? (
                    <>
                      <li>
                        <a onClick={logout}>
                          Logout
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a onClick={() => navigate("/Profile")}>Profile</a>{" "}
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a onClick={() => navigate("/Login")}>Login</a>
                      </li>
                      <li> 
                        <a onClick={() => navigate("/Register")} >register</a> 
                      </li>
                    </>
                  )}
                </ul>
              </li> 
            </ul>
          </div>
          {/* <div className="col-lg-6 text-end">
            <ul className="header-dropdown">
              <li className="mobile-wishlist">
                <a href="#">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </a>
              </li>
              <li className="mobile-wishlist mx-2" style={{ fontSize: "24px"  }} 
                          onClick={() => openCart()}
                        >
                          <div>
                            <img
                              src="./src/assets/images/icon/cart-1.png"
                              className="img-fluid blur-up lazyload"
                              style={{color:'#F0583D'}}
                              alt=""
                            /> 
                          <span className="badge ">
                            {cart ? cart.length : "0"}  
                          </span> 
                          </div>
                        </li> 
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css.css"
import { GetHomeSlider } from '../data/productsData'; 
import useWindowSize from "./useWindowSize";
import { adminUrl } from '../../public/src/urls';
import { ShopNow } from './ShopNow';
import { CurrencyConverter } from './Currency';

// BlogSection component
export const BlogSection = () => {
  const { isMobile } = useWindowSize(); 
    const [products, setProducts] = useState([]); 

    useEffect(() => {
      // Assuming GetHomeSlider is an async function
      const fetchProducts = async () => {
        try {
              await GetHomeSlider(setProducts); 
        } catch (error) {
          console.error("Failed to fetch products:", error);
        }
      };
      
      fetchProducts();
    }, []);
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,  
  };
//src date text
  return (
    <section className="blog ratio2_3 gym-blog section-b-space overflow-hidden" 
    style={{opacity:'0.8' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12"> 
            <Slider {...settings} className="slide-3 no-arrow slick-default-margin">
              {products.map((item, index) => (
                <div key={index} className="blog-slide" onClick={() => ShopNow(item)}>
                  {/* <a href="#" tabIndex="0"> */}
                    <div className="classic-effect"
                    style={{
                          borderRadius:'10px'
                     }}
                      >
                      <div
                        className="bg-size blur-up lazyloaded"
                        style={{
                          backgroundImage: `url(${adminUrl}/backend/${item.Photo})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          display: "block",
                          borderRadius:'10px'
                          
                        }}
                      >
                        <img
                          alt=""
                          src={item.src}
                          className="img-fluid blur-up lazyload bg-img"
                          style={{ display: "none"  }} 
                          readOnly 
                        />
                        <span></span>
                      </div>
                    </div>
                  {/* </a> */}
                  <div className="blog-details">
                    <h4>{item.HasDiscount ? CurrencyConverter(item.DiscountPrice) : item.Price > 0 ? CurrencyConverter(item.Price) : ''}</h4>
                    <a href="#" tabIndex="0">
                      <p>{item.ProductName}</p>
                    </a>
                    <h6>by: {item.ShopName}</h6>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

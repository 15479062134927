import { useState, useEffect, useRef } from "react";
import { GetHomeSlider } from "../data/productsData";
import { adminUrl } from "../../public/src/urls"; 
import { ShopNow } from "./ShopNow";
import PropTypes from "prop-types";
import useWindowSize from "./useWindowSize";

export default function HomeSlider() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await GetHomeSlider(setProducts);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, []);
  const { isMobile } = useWindowSize();
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide carousel-dark"
      data-bs-ride="carousel"
      style={{ 
        height: isMobile ? '' : '100vh',  
        contain:'content',
        margin: '0', 
        padding: '0' 
      }} 
    >
      <ol className="carousel-indicators">
        {products.length > 0 &&
          products.map((_, index) => (
            <li
              key={index}
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
      </ol>
      <div className="carousel-inner" 
      style={{ 
        height: '100%' ,        
        backgroundColor: isMobile ? 'red' : '' ,
        }}>
        {products.length > 0 ? (
          products.map((product, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`} 
            >
              <AutoFitImage
                src={adminUrl + `/backend/${product.Photo}`}
                alt={product.ProductName}
                style={{ 
                  height: '100vh', 
                 }}
              />
              <div className="carousel-caption d-none d-md-block text-left"
                style={{ 
                  position: 'absolute', 
                  left: 0, bottom: 0,
                   padding: '3%', 
                   textAlign: 'start', 
                   zIndex:'800' , 
                  }}
              >
                <h2 style={{color:'#F0583D', fontWeight:'600'}}>{product.ShopName}</h2>
                <h1 style={{color:'#003', fontWeight:'900'}}>{product.ProductName}</h1>
                <a
                  onClick={() => ShopNow(product)}
                  className="btn btn-primary"
                >
                  Shop Now
                </a>
              </div>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </a>
    </div>
  );
}

function AutoFitImage({ src, alt }) {
  const [objectFit, setObjectFit] = useState('cover');
  const imgRef = useRef(null);

  useEffect(() => {
    const handleImageLoad = () => {
      const img = imgRef.current;
      if (img) {
        const containerRatio = img.parentElement.clientWidth / img.parentElement.clientHeight;
        const imageRatio = img.naturalWidth / img.naturalHeight;

        // Toggle between 'cover' and 'contain' based on the aspect ratios
        if (imageRatio > containerRatio) {
          setObjectFit('contain');
        } else {
          setObjectFit('cover');
        }
      }
    };

    const img = imgRef.current;
    if (img && img.complete) {
      handleImageLoad(); // If the image is already loaded
    } else if (img) {
      img.addEventListener('load', handleImageLoad); // When the image loads
    }

    return () => {
      if (img) {
        img.removeEventListener('load', handleImageLoad);
      }
    };
  }, [src]);

  return (
    <img
      ref={imgRef}
      src={src}
      alt={alt}
      className="carousel-img"
      style={{
        objectFit: objectFit,
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
         display: 'inline-block'
      }}
    />
  );
}

// Define prop types for the component
AutoFitImage.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.object.isRequired,
};

// Optionally define default props if needed
AutoFitImage.defaultProps = {
  src: {},
  alt: {},
};

import { useEffect, useState } from "react";
import { GetNewProducts } from "../../data/productsData";
import { adminUrl } from "../../../public/src/urls";
// import { ShopNow } from "../ShopNow";
import useShopNow from "../ShopNow";
import { CurrencyConverter } from "../Currency";

export const NewProducts = () => {
  const [newProducts, setNewProducts] = useState([]);
  const shopNow = useShopNow();

  useEffect(() => {
    async function fetchData() {
      await GetNewProducts(setNewProducts);
    }
    fetchData();
  }, []);

  

  const [currentIndex, setCurrentIndex] = useState(0);
  const numberOfProduct = 5;

  const handleNext = () => {
    if (currentIndex < newProducts.length - numberOfProduct) {
      setCurrentIndex(prevIndex => prevIndex + numberOfProduct);
    } else {
      setCurrentIndex(0); // Reset to the beginning if at the end
    }
  };

  // Set up an interval to run handleNext every 1 second
  useEffect(() => {
    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [currentIndex, newProducts.length]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - numberOfProduct);
    }
  };
  
  return (
    <div className="d-xl-block d-none" >
      <div className="theme-card demo-card" 
    style={{
      borderRadius: ' 24px' }}>
        <h5 className="title-border">new product</h5>
        <div className="offer-slider no-arrow slide-1 slick-initialized slick-slider">
          <button
            className="slick-prev slick-arrow"
            aria-label="Previous"
            type="button"
            style={{ display: "block",marginRight: '10px'   }}
            onClick={handlePrev} 
          /> 
          <div className="slick-list draggable">
            <div
              className="slick-track"
                style={{opacity: 1, width: 'auto', transform: 'translate3d(0px, 0px, 0px)'}}
            >
              {newProducts.length > 0 ? (
                <>
                  {newProducts.slice(currentIndex, currentIndex + numberOfProduct).map((newProduct1, index) => (
                    <div
                      key={index}
                      className="slick-slide slick-cloned"
                      data-slick-index="0"
                      aria-hidden="true" 
                      style={{ width: "286px", borderRadius: '10px' }}
                    >
                      <div>
                        <div style={{ width: "100%", display: "inline-block", borderRadius: '10px' }}>
                          <div className="media" style={{  borderRadius: '10px' }}>
                            <a href="" tabIndex="-0" onClick={() => shopNow(newProduct1)}>
                              <img
                                className="img-fluid blur-up lazyloaded"
                                src={
                                  adminUrl+"/backend/" +
                                  newProduct1.Photo
                                }
                                alt=""
                                style={{ width: "110px", height: "100px",   borderRadius: '10px' }}
                              />
                            </a>
                            <div className="media-body align-self-center">
                              <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>{" "}
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>{" "}
                                <i className="fa fa-star"></i>
                              </div>
                              <a
                                href="product-page(no-sidebar).html"
                                tabIndex="0"
                              >
                                <h6>{newProduct1.ProductName}</h6>
                              </a>
                              <h4>{newProduct1.HasDiscount ? CurrencyConverter(newProduct1.DiscountPrice) : newProduct1.Price > 0 ? CurrencyConverter(newProduct1.Price) : ''}</h4>
                              <del>{newProduct1.HasDiscount ? CurrencyConverter(newProduct1.DiscountPrice) : ''}</del>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                   
                </>
              ) : (
                "Loading"
              )}
            </div>
            <div></div>
          </div>
          <button
            className="slick-next slick-arrow"
            aria-label="Next"
            type="button"
            style={{ display: "block" }}            
            onClick={handleNext}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

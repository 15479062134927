import { useEffect, useState } from "react";
import { getTopTowBanners } from "../../data/topmenuData";
import { adminUrl } from "../../../public/src/urls";
import alArzStorage from "../../storage/alArzStorage";
import { ShopNow } from "../ShopNow";

export const TwoBanner = () => {
  const [twoBanners, setTopTowBanners] = useState([]);

  const fetchProducts = async (CategoryID) => {
    const productsData = await getTopTowBanners(CategoryID);
    setTopTowBanners(productsData);
  };

  useEffect(() => {
    const GenderWear = alArzStorage.getItem("GenderWear");
    fetchProducts(GenderWear ? GenderWear : 51);

    const handleStorageChange = () => {
      const newGenderWear = alArzStorage.getItem("GenderWear");
      fetchProducts(newGenderWear);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="pb-0 ratio2_1 small-section">
      <div className="container">
        <div className="row partition2">
          {twoBanners.length > 0
            ? twoBanners.map((twoBanner, index) => (
                <div className="col-md-6" key={index}>
                  <a href="#" onClick={() => ShopNow(twoBanner)}>
                    <div className="collection-banner p-right text-end">
                      <div
                        className="img-part bg-size blur-up lazyloaded"
                        style={{
                          backgroundImage: 
                            `url("${adminUrl}/backend/${twoBanner.Photo}")`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          display: "block", 
                          height: "245px",
                          borderRadius: ' 10px',
                          
                          border: '1px solid #eeebeb',
                        }}
                      >
                        <img
                          src={adminUrl+"/backend/" + twoBanner.Photo}
                          className="img-fluid blur-up lazyload bg-img"
                          alt=""
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="contain-banner banner-3">
                        <div>
                          <h4 className="text-muted">{twoBanner.ProductName}</h4>
                          <h2 className="text-dark">{twoBanner.GDesription}</h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

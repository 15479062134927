import HomeSlider from "./components/homeslider";
import { BannerSecton } from "./components/bannerSection";
import { CollectionFilter } from "./components/middlebanner/collection-filter";
import { NewProducts } from "./components/middlebanner/newProducts";
import { MarketplaceSlider } from "./components/middlebanner/MarketplaceSlider";
import { MarketplaceSlider2 } from "./components/middlebanner/MarketplaceSlider2";
import { TwoBanner } from "./components/middlebanner/two-banner";
import { TitleBasic } from "./components/middlebanner/titleBasic";
import { BlogSection } from "./components/blogSection";
// import { ShopsSlider } from "./components/shopsSlider";
import { BackBanner } from "./components/middlebanner/backBaner";

export default function Home() {
  return (
    <>
      <HomeSlider />
      <section className="banner-goggles banner-padding ratio2_1" id="offers">
        <div className="container">
          <div className="row partition3">
            <BannerSecton />
          </div>
        </div>
      </section>

      <section className="small-section pb-0" id="middlebanner">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 collection-filter">
                <div className="sticky-top-section">
                  <div className="sidenav marketplace-sidebar svg-icon-menu wo-bg">
                    <nav>
                      <div>
                        <div className="sidebar-back text-start d-xl-none d-block">
                          <i
                            className="fa fa-angle-left pe-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Back
                        </div>
                      </div>
                      <ul
                        id="sub-menu"
                        className="sm pixelstrap sm-vertical"
                        style={{
                          borderRadius: " 24px",
                        }}
                      >
                        <CollectionFilter />
                      </ul>
                    </nav>
                  </div>
                  <NewProducts />
                </div>
              </div>

              <div className="collection-content col-xl-9 p-0-xl">
                <div className="page-main-content">
                  <div className="ratio_115">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <TitleBasic />
                          <MarketplaceSlider />
                          <TwoBanner />
                          <MarketplaceSlider2 />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackBanner />
      <BlogSection />
      {/* <ShopsSlider />     */}
    </>
  );
}

import { webUrl } from "../../public/src/urls"; 
import { useNavigate } from 'react-router-dom';
import { encrypt } from './encdesc';

export const ShopNow = (product) => { 

    const CPData = {
      ProductID: product.ProductID,
      CategoryID: product.SCategoryID,
      urlShopID: product.ShopID
    }; 

    const data = encrypt(CPData)
    
    // setCookie("ShopID", product.ShopID, 7);
    const url = `${webUrl}/ProductDetails.html?id=${data}`;
 
    if (data) {
      window.open(url, "_blank"); 
    } else {
      console.error("Encryption failed");
    }
  }; 


const useShopNow = () => {
    const navigate = useNavigate();

    const shopNow = (product) => { 
        const CPData = {
            ProductID: product.ProductID,
            CategoryID: product.SCategoryID,
            urlShopID: product.ShopID,
        };
        const encryptedData = encrypt(CPData);

        if (encryptedData) {
          localStorage.setItem('encryptedData', encryptedData);
            navigate('/Product', { state: { encryptedData } });
        } else {
            console.error("Encryption failed");
        }
    };

    return shopNow;
};

export default useShopNow;

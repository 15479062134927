import { adminUrl } from "../../public/src/urls";
import { CurrencyConverter } from "../components/Currency";
import { QuickViewModal } from "../components/QuickViewModal";
import useShopNow from "../components/ShopNow";
import { GetBrands } from "../data/brandsData";
import { GetCategories } from "../data/categoriesData";
import { GetFilteredProducts } from "../data/productsData";
import { AllColorShades, GetColors } from "../data/productInfos";

import { useEffect, useState } from "react";

export default function Categories() {
  const [products, setProducts] = useState([]);
  const shopNow = useShopNow();
  // useEffect(() => {
  //   // Assuming GetHomeSlider is an async function
  //   const fetchProducts = async () => {
  //     try {
  //       await GetHomeSlider(setProducts);
  //     } catch (error) {
  //       console.error("Failed to fetch products:", error);
  //     }
  //   };

  //   fetchProducts();
  // }, []);

  const repeatedProducts = Array(1).fill(products).flat();
  const [filterIsVisible, setFilterIsVisible] = useState(false);
  const [filterOverlayIsVisible, setFilterOverlayIsVisible] = useState(false);
  const [productsParPage, setProductsParPage] = useState(24);
  const [curentPageNumber, setCurentPageNumber] = useState(1);
  const [isLowTohight, setIsLowTohight] = useState(true);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [SelectedCategories, setSelectedCategories] = useState();
  const [SelectedBrands, setSelectedBrands] = useState();

  const handelClear = () => {
    setSelectedColors([])
    setSelectedColor([])
    setSelectedCategories([])
    setSelectedBrands([])
  }
  const toggleFilterVisibility = () => {
    setFilterIsVisible((prevState) => !prevState);
    setFilterOverlayIsVisible((prevState) => !prevState);
  };
  const handelSelectColor = async (color, i) => {
    const allColors = AllColorShades;
    const validSelectedColors = Array.isArray(selectedColors)
      ? selectedColors
      : [];

      let updatedColorSelected;
  if (validSelectedColors.some(arr => JSON.stringify(arr) === JSON.stringify(allColors[color]))) {
    // If the color array is already in the selected colors, remove it
    updatedColorSelected = validSelectedColors.filter(arr => JSON.stringify(arr) !== JSON.stringify(allColors[color]));
  } else {
    // Otherwise, add the new color array to the selected colors and merge all arrays into one
    updatedColorSelected = [...validSelectedColors.flat(), ...allColors[color]];
  }

  // Remove duplicates from the merged array (optional)
  updatedColorSelected = [...new Set(updatedColorSelected)];

    let updatedColorIndex;
    const validSelectedColor = Array.isArray(selectedColor)
      ? selectedColor
      : [];
    if (validSelectedColor.includes(i)) {
      updatedColorIndex = validSelectedColor.filter((item) => item !== i);
    } else {
      updatedColorIndex = [...validSelectedColor, i];
    }  
    await setSelectedColors(updatedColorSelected);
    await setSelectedColor(updatedColorIndex);
  };

  useEffect(() => { 
      GetFilteredProducts().then((filteredData) => {
      setProducts(filteredData);
    }).catch((error) => {
      console.error('Error fetching filtered products:', error);
    }); 
  }, [selectedColors, selectedColor]);

  const handelApplyFilter = async () => {  
    const data = {colors:selectedColors, Categories:SelectedCategories && SelectedCategories.CategoryID , Brands:SelectedBrands && SelectedBrands.BrandID}
   await GetFilteredProducts(data).then((filteredData) => {
      setProducts(filteredData);
    }).catch((error) => {
      console.error('Error fetching filtered products:', error);
    }); 
    setFilterIsVisible(false)
    setFilterOverlayIsVisible(false)
  };

  const [pageNumbers, setPageNumbers] = useState([]);

  const startProduct = (curentPageNumber - 1) * productsParPage + 1;
  const endProduct = Math.min(
    curentPageNumber * productsParPage,
    repeatedProducts.length
  );

  //GetCategories
  const [Categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchData();
  }, []);

  //GetBrands
  const [Brands, setBrands] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetBrands();
        setBrands(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const totalPages = Math.ceil(repeatedProducts.length / productsParPage);
    setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [repeatedProducts.length, productsParPage]);

  const productParPage = async (e) => {
    setProductsParPage(e);
    setCurentPageNumber(1);
  };

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const openQuickView = async (product) => {
    await setSelectedProduct(product);
    setIsModalOpen(true);
  };
  return (
    <>
      {isModalOpen && (
        <QuickViewModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false) + setSelectedProduct()}
        />
      )}

      <div className="collection-wrapper">
        <div className="container">
          <div className="row">
            <div className="collection-content col">
              <div className="page-main-content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="top-banner-wrapper">
                      <a href="#">
                        <img
                          src="../assets/images/mega-menu/2.jpg"
                          className="img-fluid blur-up lazyloaded"
                          alt=""
                        />
                      </a>
                      <div className="top-banner-content small-section">
                        <h4>BIGGEST DEALS ON TOP BRANDS</h4>
                        <p></p>
                      </div>
                    </div>
                    <div className="collection-product-wrapper">
                      <div className="product-top-filter">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="col-12">
                              <div className="product-filter-content">
                                <div className="search-count">
                                  <h5
                                    className="filter-bottom-title"
                                    onClick={() => toggleFilterVisibility()}                                  
                                  >
                                    filter-panel
                                  </h5>
                                  <h5></h5>
                                </div>
                                <div className="collection-view">
                                  <ul>
                                    <li>
                                      <i className="fa fa-th grid-layout-view"></i>
                                    </li>
                                    <li>
                                      <i className="fa fa-list-ul list-layout-view"></i>
                                    </li>
                                  </ul>
                                </div>
                                <div className="collection-grid-view">
                                  <ul>
                                    <li>
                                      <img
                                        src="../assets/images/icon/2.png"
                                        alt=""
                                        className="product-2-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/3.png"
                                        alt=""
                                        className="product-3-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/4.png"
                                        alt=""
                                        className="product-4-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/6.png"
                                        alt=""
                                        className="product-6-layout-view"
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-page-per-view">
                                  <select
                                    defaultValue={productsParPage}
                                    onChange={(e) =>
                                      productParPage(e.target.value)
                                    }
                                  >
                                    <option value="24">
                                      24 Products Par Page
                                    </option>
                                    <option value="50">
                                      50 Products Par Page
                                    </option>
                                    <option value="100">
                                      100 Products Par Page
                                    </option>
                                  </select>
                                </div>
                                <div className="product-page-filter">
                                  <select
                                    defaultValue={true}
                                    onChange={(e) =>
                                      setIsLowTohight(e.target.value === "true")
                                    }
                                  >
                                    <option value="true">Low to high</option>
                                    <option value="false">High to low</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="collection-filter container-fluid top-filter filter-bottom-content"
                                style={{
                                  display: filterOverlayIsVisible ? "block" : "none",
                                  height:'100vh',
                                  width:'100vw',
                                  backgroundColor:'black',
                                  position:'fixed',
                                  top:'0',
                                  left:'0',
                                  opacity:0.7
                                }}
                                onClick={()=>toggleFilterVisibility()}
                              >
                                 
                                </div>
                              <div
                                className="collection-filter container-fluid top-filter filter-bottom-content"
                                style={{
                                  display: filterIsVisible ? "block" : "none",
                                }}
                              >
                                <div className="collection-filter-block row m-0">
                                  <div className="collection-mobile-back col-12">
                                    <span className="filter-back">
                                      <i
                                        className="fa fa-angle-left"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      back
                                    </span>
                                  </div>
                                  <div className="collection-collapse-block open col-lg-3">
                                    <h3 className="collapse-block-title">
                                      Categories
                                    </h3>
                                    <div className="collection-collapse-block-content">
                                      <div className="collection-brand-filter">
                                        {Categories &&
                                          Categories.map((category, i) => (
                                            <div
                                              key={i}
                                              className="form-check collection-filter-checkbox"
                                            >
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={category.CategoryID}
                                                onClick={() =>
                                                  setSelectedCategories(
                                                    category
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={category.CategoryID}
                                              >
                                                {category.Category}
                                              </label>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="collection-collapse-block open col-lg-3">
                                    <h3 className="collapse-block-title">
                                      brands
                                    </h3>
                                    <div className="collection-collapse-block-content">
                                      <div className="collection-brand-filter">
                                        {Brands &&
                                          Brands.map((brand, i) => (
                                            <div
                                              key={i}
                                              className="form-check collection-filter-checkbox"
                                            >
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={brand.BrandID}
                                                onClick={() =>
                                                  setSelectedBrands(brand)
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={brand.BrandID}
                                              >
                                                {brand.Brand}
                                              </label>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="collection-collapse-block open col-lg-3">
                                    <h3 className="collapse-block-title">
                                      colors
                                    </h3>
                                    <div className="collection-collapse-block-content">
                                      <div className="color-w-name">
                                        <ul className="row">
                                          {GetColors &&
                                            GetColors.map((color, i) => (
                                              <li
                                                key={i}
                                                className={
                                                  selectedColor.includes(i)
                                                    ? "active col-12"
                                                    : "col-12"
                                                }
                                                onClick={() =>
                                                  handelSelectColor(color, i)
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor: color,
                                                    color:
                                                      color === 'Black' &&
                                                      selectedColor.includes(i)
                                                        ? '#fff'
                                                        : '#fff',  
                                                  }}
                                                ></span>
                                                {color} 
                                              </li>
                                            ))}
                                          {/*   
                                            grey blue purple   */}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="collection-collapse-block border-0 open col-lg-3">
                                    <h3 className="collapse-block-title">
                                      size
                                    </h3>
                                    <div className="collection-collapse-block-content">
                                      <div className="collection-brand-filter">
                                        <div className="form-check collection-filter-checkbox">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="hundred"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="hundred"
                                          >
                                            s
                                          </label>
                                        </div>
                                        <div className="form-check collection-filter-checkbox">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="twohundred"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="twohundred"
                                          >
                                            m
                                          </label>
                                        </div>
                                        <div className="form-check collection-filter-checkbox">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="threehundred"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="threehundred"
                                          >
                                            l
                                          </label>
                                        </div>
                                        <div className="form-check collection-filter-checkbox">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="fourhundred"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="fourhundred"
                                          >
                                            xl
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="collection-collapse-block border-0 open col-lg-3">
                                    <h3 className="collapse-block-title">
                                      price
                                    </h3>
                                    <div className="collection-collapse-block-content">
                                      <div className="wrapper mt-3">
                                        <div className="range-slider">
                                          <span className="irs js-irs-0">
                                            <span className="irs">
                                              <span
                                                className="irs-line"
                                                tabIndex="-1"
                                              >
                                                <span className="irs-line-left"></span>
                                                <span className="irs-line-mid"></span>
                                                <span className="irs-line-right"></span>
                                              </span>
                                              <span
                                                className="irs-min"
                                                style={{ visibility: "hidden" }}
                                              >
                                                $0
                                              </span>
                                              <span
                                                className="irs-max"
                                                style={{ visibility: "hidden" }}
                                              >
                                                $1.500
                                              </span>
                                              <span
                                                className="irs-from"
                                                style={{
                                                  visibility: "visible",
                                                  left: "0%",
                                                }}
                                              >
                                                $0
                                              </span>
                                              <span
                                                className="irs-to"
                                                style={{
                                                  visibility: "visible",
                                                  left: "85.5738%",
                                                }}
                                              >
                                                $1.500
                                              </span>
                                              <span
                                                className="irs-single"
                                                style={{
                                                  visibility: "hidden",
                                                  left: "38.5246%",
                                                }}
                                              >
                                                $0 - $1.500
                                              </span>
                                            </span>
                                            <span className="irs-grid"></span>
                                            <span
                                              className="irs-bar"
                                              style={{
                                                left: "1.31148%",
                                                width: "97.377%",
                                              }}
                                            ></span>
                                            <span
                                              className="irs-shadow shadow-from"
                                              style={{ display: "none" }}
                                            ></span>
                                            <span
                                              className="irs-shadow shadow-to"
                                              style={{ display: "none" }}
                                            ></span>
                                            <span
                                              className="irs-slider from"
                                              style={{ left: "0%" }}
                                            ></span>
                                            <span
                                              className="irs-slider to"
                                              style={{ left: "97.377%" }}
                                            ></span>
                                          </span>
                                          <input
                                            type="text"
                                            className="js-range-slider irs-hidden-input"
                                            value=""
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="text-end button_bottom">
                                      <a
                                        className="btn btn-solid btn-xs me-2"
                                        onClick={() => handelApplyFilter()}
                                      >
                                        apply
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-solid btn-xs close-filter-bottom"
                                      >
                                        close filter
                                      </a>
                                      <a
                                         
                                        className="btn btn-solid btn-xs close-filter-bottom mx-2"
                                        onClick={()=>handelClear()}
                                      >
                                        Clear
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Loop */}
                      <div className="product-wrapper-grid">
                        <div className="row margin-res">
                          {products.length > 0
                            ? repeatedProducts
                                .sort((a, b) =>
                                  isLowTohight
                                    ? a.Price - b.Price
                                    : b.Price - a.Price
                                )
                                .slice(
                                  (curentPageNumber - 1) * productsParPage,
                                  productsParPage * curentPageNumber
                                )
                                .map((product, index) => {
                                  // const ProductDetailstruncatedHtml =
                                  //   truncateText(product.ProductDetails, 250);

                                  return (
                                    <div
                                      className="col-xl-3 col-6 col-grid-box"
                                      key={index}
                                    >
                                      <div className="product-box">
                                        <div className="img-wrapper">
                                          <div className="front">
                                            <a
                                              href="product-page(no-sidebar).html"
                                              className="bg-size blur-up lazyloaded  "
                                              style={{
                                                backgroundImage: `url("${adminUrl}/backend/${product.Photo}")`,
                                                backgroundSize: "cover",
                                                backgroundPosition:
                                                  "center center",
                                                display: "block",
                                                height: "160px",
                                                borderRadius: " 10px",
                                                border: "1px solid #eeebeb",
                                              }}
                                              onClick={() => shopNow(product)}
                                            >
                                              <img
                                                src={
                                                  adminUrl +
                                                  "/backend/" +
                                                  product.Photo
                                                }
                                                className="img-fluid blur-up lazyload bg-img"
                                                alt=""
                                                style={{ display: "none" }}
                                              />
                                            </a>
                                          </div>
                                          <div className="back">
                                            <a
                                              href="#"
                                              className="bg-size blur-up lazyloaded"
                                              style={{
                                                backgroundImage: `url(" ${adminUrl}/backend/${product.Photo} ")`,
                                                backgroundSize: "cover",
                                                backgroundPosition:
                                                  "center center",
                                                display: "block",
                                                height: "160px",
                                                borderRadius: " 10px",
                                                border: "1px solid #eeebeb",
                                              }}
                                              onClick={() => shopNow(product)}
                                            >
                                              <img
                                                src={
                                                  adminUrl +
                                                  "/backend/" +
                                                  product.Photo
                                                }
                                                className="img-fluid blur-up lazyload bg-img"
                                                alt=""
                                                style={{ display: "none" }}
                                              />
                                            </a>
                                          </div>
                                          <div className="cart-info cart-wrap">
                                            <button
                                              data-bs-toggle="modal"
                                              data-bs-target="#addtocart"
                                              title="Add to cart"
                                            >
                                              <i className="ti-shopping-cart"></i>
                                            </button>{" "}
                                            <a href="#" title="Add to Wishlist">
                                              <i
                                                className="ti-heart"
                                                aria-hidden="true"
                                              ></i>
                                            </a>{" "}
                                            <a
                                              href="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#quick-view"
                                              title="Quick View"
                                              onClick={() =>
                                                openQuickView(product)
                                              }
                                            >
                                              <i
                                                className="ti-search"
                                                aria-hidden="true"
                                              ></i>
                                            </a>{" "}
                                            <a
                                              href="compare.html"
                                              title="Compare"
                                            >
                                              <i
                                                className="ti-reload"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="product-detail">
                                          <div>
                                            <div className="rating">
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>
                                            </div>
                                            <a>
                                              {/* <h6
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    ProductDetailstruncatedHtml,
                                                }}
                                              /> */}
                                              <h6>{product.ProductName}</h6>
                                            </a>

                                            <h4>
                                              {product.HasDiscount
                                                ? CurrencyConverter(
                                                    product.DiscountPrice
                                                  )
                                                : product.Price > 0
                                                ? CurrencyConverter(
                                                    product.Price
                                                  )
                                                : ""}
                                            </h4>
                                            <del>
                                              {product.HasDiscount
                                                ? CurrencyConverter(
                                                    product.Price
                                                  )
                                                : ""}
                                            </del>
                                            <ul className="color-variant">
                                              <li className="bg-light0"></li>
                                              <li className="bg-light1"></li>
                                              <li className="bg-light2"></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                            : ""}
                        </div>
                      </div>
                      <div className="product-pagination">
                        <div className="theme-paggination-block">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-xl-6 col-md-6 col-sm-12">
                                <nav aria-label="Page navigation">
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="#"
                                        aria-label="Previous"
                                        onClick={() =>
                                          curentPageNumber !== 0
                                            ? setCurentPageNumber(
                                                curentPageNumber - 1
                                              )
                                            : alert(pageNumbers)
                                        }
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-left"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </a>
                                    </li>
                                    {pageNumbers.map((number) => (
                                      <li key={number} className="page-item">
                                        <a
                                          className="page-link"
                                          style={
                                            number === curentPageNumber
                                              ? {
                                                  backgroundColor: "#F0583D",
                                                  color: "#fff",
                                                }
                                              : {}
                                          }
                                          href="#"
                                          onClick={() =>
                                            setCurentPageNumber(number)
                                          }
                                        >
                                          {number}
                                        </a>
                                      </li>
                                    ))}
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="#"
                                        aria-label="Next"
                                        onClick={() =>
                                          pageNumbers.length > curentPageNumber
                                            ? setCurentPageNumber(
                                                curentPageNumber + 1
                                              )
                                            : alert(pageNumbers)
                                        }
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-right"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="product-search-count-bottom">
                                  <h5>
                                    Showing Products {startProduct} -{" "}
                                    {endProduct} of {repeatedProducts.length}{" "}
                                    Results
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

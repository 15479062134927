class AlArzStorage {
    constructor() {
      this.storage = {};
    }
  
    setItem(key, value) {
      this.storage[key] = value;
    }
  
    getItem(key) {
      return this.storage[key] || null;
    }
  
    removeItem(key) {
      delete this.storage[key];
    }
  
    clear() {
      this.storage = {};
    }
    getAllItems() {
      return this.storage;
    }
    getAllKeys() {
      return Object.keys(this.storage);
    }
  }
  
  const alArzStorage = new AlArzStorage();

  export default alArzStorage;
  
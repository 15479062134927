import { createRoot } from "react-dom/client";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./components/AuthContext";

// Import your components
import Home from "./home";
import Categories from "./categories";
import ProductInfo from "./product";
import { Login } from "./user/login";
import { Cart } from "./cart";
import { Wishlist } from "./Wishlist";
import { Profile } from "./user/Profile";
import { Register } from "./user/register"; 

function NotFound() {
  const navigate = useNavigate()
  return ( 
     <section className="p-0">
     <div className="container">
         <div className="row">
             <div className="col-sm-12">
                 <div className="error-section">
                     <h1>404</h1>
                     <h2>page not found</h2>
                     <a onClick={()=>navigate('/')} className="btn btn-solid">back to home</a>
                 </div>
             </div>
         </div>
     </div>
 </section>
  );
}

export default function ArzRouter() {
  const { isAuthenticated } = useContext(AuthContext);  

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/Wishlist" element={<Wishlist />} />
          <Route path="/Profile" element={<Profile />} />
        </>
      ) : (
        <>
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
        </>
      )}

      <Route path="/" element={<Home />} />
      <Route path="/Categories" element={<Categories />} />
      <Route path="/Product" element={<ProductInfo />} />
      <Route path="/Cart" element={<Cart />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render();

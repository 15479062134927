import { ShopsSlider } from "../shopsSlider";
import useWindowSize from "../useWindowSize";

export const BackBanner = () => { 
    const { isMobile } = useWindowSize();
    const styles = createStyles({ isMobile });
  return (
    <>
      {/* <div style={styles.mainDiv }> */}
        <ShopsSlider style={styles.logos } />
        {/* </div>  */}
    </>
  );
};

const createStyles = ({isMobile}) => ({
  mainDiv: {
    backgroundColor: "lightblue",
    backgroundImage: `url('https://media.cntravellerme.com/photos/64adaa3b8065f76731bd4b67/16:9/w_1280,c_limit/Hero-Dubai-Mall-Fashion-Avenue-Dubai-Alamy-R9FRB7.jpg')`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment:'fixed',
    backgroundPosition:'center',
    backgroundSize: "cover",
    padding: isMobile ? "140px 0px 140px 0px" : "240px 50% 0 0px",
    height: "100vh", 
     marginTop:'1%'
  },
  logos: { 

    marginTop:'3%',  
    opacity: "0.6",  
    height: isMobile ? "50px" : "25%",  
  },
});


import { useState, useEffect } from "react";
import { GetBanners } from "../data/productsData";
import useWindowSize from "./useWindowSize";
import { adminUrl } from "../../public/src/urls";
import { ShopNow } from "./ShopNow";
import { CurrencyConverter } from "./Currency";

export const BannerSecton = () => {
  const { isMobile } = useWindowSize();

  const [banners, setBanners] = useState([]);
  useEffect(() => {
    GetBanners(setBanners);
  }, []);

  return (
    <>
      {banners.length > 0
        ? (isMobile ? banners.slice(0, 2) : banners).map((banner, index) => (
            <div className={"col-lg-4 col-6 m-0"} key={index}>
              <div
                className="mordern-box"
                style={{
                  minHeight: isMobile ? "" : "30vh",
                  width: isMobile ? "100%" : "",
                  height: isMobile ? "50%" : "auto",
                }}
              >
                <div className="absolute-img">
                  <img
                    src={adminUrl + `/backend/${banner.Photo}`}
                    // className="img-fluid"
                    alt=""
                    style={{
                      width: isMobile ? "100%" : "",
                      height: isMobile ? "25vh" : "302px",
                      boxShadow: "2px 4px 8px rgba(4, 8, 5, 0.3)",
                    }}
                  />
                </div>
                <div className="mordern-content">
                  <a href="product-page(no-sidebar).html">
                    <h6
                      style={{
                        fontSize: isMobile
                          ? "clamp(1rem, 2vw, 2rem)"
                          : "" /* Adjusts font size between 1rem and 2rem based on viewport width */,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {banner.ProductName}
                    </h6>
                  </a> 
                    <div
                      dangerouslySetInnerHTML={{
                        __html: banner.ProductDetails.substring(0, 100) + '... <a href="#">Read More</a>',
                        
                      }}
                      onClick={() => ShopNow(banner)}
                    /> 
                    
                  <div className="mordern-bottom">
                    <div className="left">
                      <h4>
                        {CurrencyConverter(banner.DiscountPrice)} <del>{CurrencyConverter(banner.Price)}</del>
                      </h4>
                      <ul className="color-variant">
                        <li className="bg-light0"></li>
                        <li className="bg-light1"></li>
                        <li className="bg-light2"></li>
                      </ul>
                    </div>
                    {/* <div className="right">
                      <div className="add-extent d-none d-md-block">
                        <i className="fa fa-plus animated-btn"></i>
                        <div className="options">
                          <ul>
                            <li>
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#addtocart"
                                title="Add to cart"
                              >
                                <i className="ti-shopping-cart"></i>
                              </button>
                            </li>
                            <li>
                              <a href="" title="Add to Wishlist">
                                <i className="ti-heart" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#quick-view"
                                title="Quick View"
                              >
                                <i className="ti-search" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li>
                              <a href="compare.html" title="Compare">
                                <i className="ti-reload" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))
        : "Loading"}
    </>
  );
};

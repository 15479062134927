export const GetShops = async () => {
    try {
      const response = await fetch("/api/", {  
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: 'GetShops' }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
   
      const data = await response.json();
      const Shops = data?.data || [];   
      // setShops(data.data)  
      // console.log(Shops)
      return Shops;  

    } catch (error) {
      console.error('Error fetching data:', error);   
    }
  };
  
import   { useEffect, useState } from "react";
import { GetProductsByCategories } from "../../data/productsData";
import './MarketplaceSlider.css'; // Import the CSS file
import { adminUrl } from "../../../public/src/urls";
import useWindowSize from "../useWindowSize";
import alArzStorage from "../../storage/alArzStorage";
import { ShopNow } from "../ShopNow";
import { CurrencyConverter } from "../Currency";
import { QuickViewModal } from "../QuickViewModal";

export const MarketplaceSlider = () => {
  const [products, setProducts] = useState([]); 
  const [animationClass, setAnimationClass] = useState('');

  const { isMobile } = useWindowSize(); 

  const productsPerPage = isMobile ? 2 : 5; 

  const fetchProducts = async (CategoryID) => {    
    const productsData = await GetProductsByCategories(CategoryID);
    setProducts(productsData);
  };
  useEffect(() => {
    const CategoryID = alArzStorage.getItem('CategoryID');
    fetchProducts(CategoryID ? CategoryID : 1 );

    const handleStorageChange = () => {
      const newCategoryID = alArzStorage.getItem('CategoryID');
      fetchProducts(newCategoryID);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < products.length - productsPerPage) {
      setCurrentIndex(prevIndex => prevIndex + productsPerPage);
    } else {
      setCurrentIndex(0);  
    }
  };
 
  useEffect(() => {
    const interval = setInterval(handleNext, 15000);
    return () => clearInterval(interval); // Cleanup the interval on unmount
  });

  // const handlePrev = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - productsPerPage);
  //   }
  // };
  

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const openQuickView = async (product) => {
    // console.log(product)
   await setSelectedProduct(product);
    setIsModalOpen(true);
  };

  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1%' }}>
    

      <div className="slider-container">
        <div className={`slider-content ${animationClass}`}>
          {products.length > 0 ? (
            products.slice(currentIndex,currentIndex + productsPerPage).map((product, index) => (
              <div key={index} className="slider-item"
              style={{  width: isMobile ? '45vw' : ''}}
              >
                <div className="product-box">
                  <div className="img-wrapper">
                    <div className="front">
                      <a 
                        className="bg-size blur-up lazyloaded  "
                        style={{
                          backgroundImage: `url("${adminUrl}/backend/${product.Photo}")`,
                          backgroundSize:'cover',
                          backgroundPosition: "center center",
                          display: "block", 
                          height: '160px', 
                          borderRadius: ' 10px' ,
                          border: '1px solid #eeebeb', 
   
                        }}
                        
                        onClick={() => ShopNow(product)}
                      >
                        <img
                          src={ adminUrl+"/backend/" + product.Photo}
                          className="img-fluid blur-up lazyload bg-img"
                          alt=""
                          style={{ display: "none" }}
                        />
                      </a>
                    </div>
                    <div className="cart-info cart-wrap bg-color-cls sm-box">
                      {/* <button title="Add to cart">
                        <i className="ti-shopping-cart"></i>
                      </button> */}
                      <a  title="Add to Wishlist">
                        <i className="ti-heart" aria-hidden="false"></i>
                      </a>
                      <a 
                        data-bs-toggle="modal"
                        data-bs-target="#quick-view"
                        title="Quick View"
                        onClick={() => openQuickView(product)}
                      >
                        <i className="ti-search" aria-hidden="false"></i>
                      </a>
                      <a href="compare.html" title="Compare">
                        <i className="ti-reload" aria-hidden="false"></i>
                      </a>
                    </div>
                  </div>
                  <div className="product-detail">
                    <div className="rating">
                      <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i>
                    </div>
                    <a href="product-page(no-sidebar).html">
                      <h6>{product.ProductName}</h6>
                    </a>
                    <h4>{product.HasDiscount ? CurrencyConverter(product.DiscountPrice) :   product.Price > 0 ? CurrencyConverter(product.Price) : ''}</h4>
                    <del>{product.HasDiscount ? CurrencyConverter(product.Price) : ''} <br /> </del>
                    <ul className="color-variant">
                      <li className="bg-color1"></li>
                      <li className="bg-color3"></li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          ) : (
            'Loading...'
          )}
        </div>
      </div>

      {/* <button
        style={{ marginLeft: '10px' }}
        className="slick-prev slick-arrow"
        onClick={() => {
          setAnimationClass('slide-out-right');
          setTimeout(() => {
            currentIndex((prevIndex) => (prevIndex + productsPerPage) % products.length);
            setAnimationClass('slide-in-left');
          }, 500);  
        }}
      >
        Next
      </button> */}
    </div>
    
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1%' }}> 

      <div className="slider-container">
        <div className={`slider-content ${animationClass}`}>
          {products.length > 0 ? (
            products.slice(currentIndex,currentIndex + productsPerPage).map((product, index) => (
              <div key={index} className="slider-item"
              style={{  width: isMobile ? '45vw' : ''}}>
                <div className="product-box">
                  <div className="img-wrapper">
                    <div className="front">
                      <a
                        onClick={() => ShopNow(product)}
                        className="bg-size blur-up lazyloaded  "
                        style={{
                          backgroundImage: `url("${adminUrl}/backend/${product.Photo}")`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          display: "block",
                        //   width: '210px',
                          height: '160px', 
                          border: '1px solid #eeebeb',
                          borderRadius: ' 10px' 
                        }}
                      >
                        <img
                          src={ adminUrl+"/backend/" + product.Photo}
                          className="img-fluid blur-up lazyload bg-img"
                          alt=""
                          style={{ display: "none" }}
                        />
                      </a>
                    </div>
                    <div className="cart-info cart-wrap bg-color-cls sm-box">
                      <button title="Add to cart">
                        <i className="ti-shopping-cart"></i>
                      </button>
                      <a href="" title="Add to Wishlist">
                        <i className="ti-heart" aria-hidden="false"></i>
                      </a>
                      <a 
                        data-bs-toggle="modal"
                        data-bs-target="#quick-view"
                        title="Quick View" 
                        onClick={() => openQuickView(product)}
                      >
                        <i className="ti-search" aria-hidden="false"></i>
                      </a>
                      <a href="compare.html" title="Compare">
                        <i className="ti-reload" aria-hidden="false"></i>
                      </a>
                    </div>
                  </div>
                  <div className="product-detail">
                    <div className="rating">
                      <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i>
                    </div>
                    <a href="product-page(no-sidebar).html">
                      <h6>{product.ProductName}</h6>
                    </a>                    
                    <h4>{product.HasDiscount ? CurrencyConverter(product.DiscountPrice) :   product.Price > 0 ? CurrencyConverter(product.Price) : ''}</h4>
                    <del>{product.HasDiscount ? CurrencyConverter(product.Price) : ''} <br /> </del>
                    <ul className="color-variant">
                      <li className="bg-color1"></li>
                      <li className="bg-color3"></li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          ) : (
            'Loading...'
          )}
        </div>
      </div>

      {/* <button
        style={{ marginLeft: '10px' }}
        className="slick-prev slick-arrow"
        onClick={() => {
          setAnimationClass('slide-out-right');
          setTimeout(() => {
            currentIndex((prevIndex) => (prevIndex + productsPerPage) % products.length);
            setAnimationClass('slide-in-left');
          }, 500); // Match the duration of the slide-out animation
        }}
      >
        Next
      </button> */}
    </div>

    {isModalOpen && <QuickViewModal product={selectedProduct} onClose={() => setIsModalOpen(false) + setSelectedProduct()} />}

    </>
  );
};

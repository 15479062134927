import { useState, useEffect } from "react";
import { getCollectionFilter } from "../../data/topmenuData";
import { GetCategoriesByTopMenu } from "../../data/categoriesData";
import { GetProductsByCategories } from "../../data/productsData";
import useWindowSize from "../useWindowSize";
import alArzStorage from "../../storage/alArzStorage";

export const CollectionFilter = () => {
  const [cllFilters, setCollectionFilter] = useState([]);
  const [categoriesByTopMenu, setCategoriesByTopMenu] = useState([]);
  const [filteredCats, setFilteredCategories] = useState([]);
  const { isMobile } = useWindowSize();
  
  useEffect(() => {
    async function fetchData() {
      await getCollectionFilter(setCollectionFilter);

      await GetCategoriesByTopMenu(setCategoriesByTopMenu);
    }
    fetchData();
  }, []);

  const [openSubmenu, setOpenSubmenu] = useState(null);

  const submenu = (index, GWearID) => {
    const filteredCategories = categoriesByTopMenu.filter(
      (category) => category.GWearID === GWearID
    );
    setFilteredCategories(filteredCategories); 
    setOpenSubmenu(index);
  };

  const handleMouseOut = () => {
    setOpenSubmenu(null);
  };
  const handleClick = (CatID,GenderWear) => {
    alArzStorage.setItem("CategoryID", CatID);
    alArzStorage.setItem("GenderWear", GenderWear);
    window.dispatchEvent(new Event("storage"));
    setOpenSubmenu(null);
  };
  return (
    <>
      {cllFilters.map((TopMenu, index) => (
        <li key={TopMenu.GWearID} style={{ position: "relative" }}>
          <a
            href="#"
            className="has-submenu"
            id={`sm-17214748597871646-${index}`}
            aria-haspopup="true"
            aria-controls={`sm-17214748597871646-${index}-sub`}
            aria-expanded={openSubmenu === index}
            onMouseEnter={() => submenu(index, TopMenu.GWearID)}
            onMouseLeave={handleMouseOut}
          >
             <svg>
              <use
                xlinkHref={"/src/assets/svg/icons.svg#" + TopMenu.photo} > 

              </use>
            </svg> 
            {TopMenu.GDesription}
            <span className="sub-arrow"></span>
          </a>
          <ul
            className="mega-menu clothing-menu sm-nowrap"
            id={`sm-17214748597871646-${index}-sub`}
            role="group"
            aria-hidden={openSubmenu !== index}
            aria-labelledby={`sm-17214748597871646-${index}`}
            onMouseEnter={() => submenu(index, TopMenu.GWearID)}
            onMouseLeave={handleMouseOut}
            style={{
              position: "absolute",
              top: "0",
              left: "100%",
              display: openSubmenu === index ? "block" : "none",
              minWidth: "10em",
              maxWidth: "20em",
              marginLeft: "0",
            }}
          >
            <li>
              <div className="row m-0">
                <h4>{TopMenu.GDesription}</h4>
                {filteredCats.map((filteredCat, i) => (
                  <div className="col-xl-4" key={i}>
                    <div
                      className="link-section "
                      style={{ cursor: "pointer" }}
                    >
                      <h5 onClick={() => handleClick(filteredCat.CategoryID, filteredCat.GWearID)}>
                        {filteredCat.Category}
                      </h5>
                    </div>
                  </div>
                ))}

                <div className="col-xl-4">
                  <a href="#" className="mega-menu-banner">
                    <img
                      src="/src/assets/images/mega-menu/fashion.jpg"
                      alt=""
                      className="img-fluid blur-up lazyloaded"
                    />
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </li>
      ))}
    </>
  );
};

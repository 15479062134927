export const getCollectionFilter = async (setCollectionFilter) => {
    try {
      const response = await fetch("/api/", {  
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: 'getTopMenu' }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();   
      setCollectionFilter(data.data);  
    } catch (error) {
      console.error('Error fetching data:', error);   
    }
  };


export const getTopTowBanners = async (GWearID) => {
    try {
      const response = await fetch("/api/", {  
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: 'getTopTowBanners', GWearID: GWearID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();   
      return data.data;  

    } catch (error) {
      console.error('Error fetching data:', error);   
    }
  };
  
export const GetCategories = async () => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetCategories" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); 
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

export const GetCategoriesByTopMenu = async (setCategoriesByTopMenu) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "getCategoryByTopMeny" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setCategoriesByTopMenu(data.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

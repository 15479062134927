import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../components/AuthContext";

export const Register = () => {
    const navigate = useNavigate();
  const [FirstName, setFirstName] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login } = useContext(AuthContext);

  const handelSubmit = () => {
    if (FirstName === "") {
      setError("FirstName cant be empty");
      return false;
    }
    if (Lastname === "") {
      setError("Lastname cant be empty");
      return false;
    }
    if (Mobile === "") {
      setError("Mobile cant be empty");
      return false;
    }
    if (Email === "") {
      setError("Email cant be empty");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(Email)) {
      setError("Invalid email format");
      return false;
    }
    if (Password === "") {
      setError("Password cant be empty");
      return false;
    }
    const newUser =  
      {
        FirstName: FirstName,
        Lastname: Lastname,
        Mobile: Mobile,
        Email: Email,
        Password: Password,
      }  
      
    try {
      const response = fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "NewUser", data: newUser }),
      });

      // const data = response.json();
      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${data.log}`);
      // }
      console.log(response.log)
      // setError(data.log);
      const userAuth1 = { user: newUser.Email, pass: newUser.Password }; 
      login(userAuth1);
   

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <section className="register-page section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3>create account</h3>
            <div className="theme-card">
              <form className="theme-form">
                <div className="form-row row">
                  <div className="col-md-6">
                    <label htmlFor="email">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="review">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      placeholder="Last Name"
                      onChange={(e) => setLastname(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-row row">
                  <div className="col-md-4">
                    <label htmlFor="email">Mobile</label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobile"
                      placeholder="mobile"
                      onChange={(e) => setMobile(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="email">email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="review">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="review"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                  {error ? (
                    <div className="alert alert-warning  mt-2" role="alert">
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                  
                  <a onClick={()=> handelSubmit()} className="btn btn-solid w-auto">
                    create Account
                  </a>

                  
                  <a onClick={()=>navigate('/Login')}  className="btn btn-link w-auto mx-2">Already have user</a>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

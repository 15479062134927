const groupColor = (rgb) => {
    const [r, g, b] = rgb.color;

    if (r >= 200 && g <= 50 && b <= 50) return 'Red';
  if (r <= 50 && g >= 200 && b <= 50) return 'Green';
  if (r <= 50 && g <= 50 && b >= 200) return 'Blue';
  if (r >= 200 && g >= 200 && b <= 50) return 'Yellow';
  if (r <= 50 && g >= 200 && b >= 200) return 'Cyan';
  if (r >= 200 && g <= 50 && b >= 200) return 'Magenta';
  if (r <= 50 && g <= 50 && b <= 50) return 'Black';
  if (r >= 200 && g >= 200 && b >= 200) return 'White';
  if (r >= 128 && g <= 128 && b >= 128) return 'Purple';
  if (r >= 100 && r <= 200 && g >= 100 && g <= 200 && b >= 100 && b <= 200) return 'Grey';
  if (r >= 200 && g >= 100 && g <= 200 && b <= 50) return 'Orange';
  if (r >= 100 && r <= 200 && g >= 50 && g <= 100 && b <= 50) return 'Brown';
  
    return 'All';
  };

 const colors = [
   {color:  '', value: 'All' } ,
   {color: [255, 0, 0], value: 'Red' } ,
   {color: [0, 255, 0], value: 'Green' } ,
   {color: [0, 0, 255], value: 'Blue' } ,
   {color: [255, 255, 0], value: 'Yellow' } ,
   {color: [0, 255, 255], value: 'Cyan' } ,
   {color: [255, 0, 255], value: 'Magenta' } ,
   {color: [0, 0, 0], value: 'Black' } ,
   {color: [255, 255, 255], value: 'White' } ,
   {color: [128, 128, 128], value: 'Grey' } ,
   {color: [255, 165, 0], value: 'Orange' }  
]

export const GetColors = colors.map(groupColor);


// Function to convert RGB to Hex
const rgbToHex = (r, g, b) => {
    const toHex = (n) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };
  
  // Function to generate hex colors for a given base color with varying shades
  const generateColorShades = (baseColor, variationRange) => {
    // console.log(baseColor)
    // console.log(variationRange)
    const [r, g, b] = baseColor;
    const shades = [];
    
    for (let i = -variationRange; i <= variationRange; i += 1) {
      const newR = Math.min(255, Math.max(0, r + i));
      const newG = Math.min(255, Math.max(0, g + i));
      const newB = Math.min(255, Math.max(0, b + i));
      shades.push(rgbToHex(newR, newG, newB));
    }
    
    return shades;
  };
  
  // Function to generate hex colors for each color in the colors array
  const getAllColorShades = (colors, variationRange) => {
    const allShades = {};
    
    colors.forEach(({ color, value }) => {
      allShades[value] = generateColorShades(color, variationRange);
    });
    
    return allShades;
  };
  
  // Generate all color shades with a variation range
  const variationRange = 30000; // Adjust the range as needed
  export const AllColorShades = getAllColorShades(colors, variationRange);
  
 
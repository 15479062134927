import { useContext, useState } from "react";
import { AuthContext } from "../components/AuthContext";

export default function LoginComponent(userAuth) {
  const { login } = useContext(AuthContext);
  const [error, setError] = useState(false);

  const handleLogin = async ({ userAuth }) => {
    if (userAuth.user === "") {
      setError("Email cant be empty");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userAuth.user)) {
      setError("Invalid email format");
      return false;
    }
    if (userAuth.pass === "") {
      setError("Password cant be empty");
      return false;
    }
    setError(false);

    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "CheckAuth", data: userAuth }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.isLogedin) {
        login(data);
      } else {
        setError(data.log);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      {/* Add your login form or UI elements here */}
      <button
        type="button"
        className="btn btn-solid"
        onClick={() => handleLogin(userAuth)}
      >
        Login
      </button>
      {error ? (
        <div className="alert alert-warning  mt-2" role="alert">
          {error}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export const GetHomeSlider = async (setProducts) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "HomeSlider" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setProducts(data.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

export const GetBanners = async (setBanners) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "BannerSection" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setBanners(data.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

export const GetNewProducts = async (setNewProducts) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetNewProducts" }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setNewProducts(data.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

export const GetProductsByCategories = async (index) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "GetProductsByCategories",
        CategoryID: index,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};

export const GetProductsByID = async ( index ) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetProductsByID", ProductID: index }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } 
    const data = await response.json();
    const products = data?.data || [];   
    return products;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

export const GetProductGaleries = async ( index ) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetProductsGaleriesByID", ProductID: index }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const products = data?.data || [];  
    return products;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

export const GetProductsInfos = async ( index ) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetProductsInfosByID", ProductID: index }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const products = data?.data || []; 
     
    return products;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

export const GetrelatedProducts = async ( index ) => { 
  
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetrelatedProductsByID", CategoryID: index }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const RelatedProducts = data?.data || [];  
    return RelatedProducts;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

export const GetFilteredProducts = async ( index ) => {  
  console.log(index)
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetFilteredProducts", filter: index}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const FilteredProducts = data?.data || [];   
    console.log(data)
    return FilteredProducts;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

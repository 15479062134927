import React, { useState, useEffect } from 'react';

export const TitleBasic = () => {
  const targetDate = "2024-07-31T23:59:59"; // Set your target date here

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} 
        <span className="timer-cal">{interval.charAt(0).toUpperCase() + interval.slice(1)}</span>
      </span>
    );
  });

  return (
    <div className="title-basic">
      <h2 className="title">
        <i className="ti-bolt"></i> deals of the day
      </h2>
      <div
        className="timer"
        style={{
          borderRadius: "10px",
        }}
      >
        <p id="demo">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </p>
      </div>
    </div>
  );
};
